











































import { Component, Mixins, Prop } from 'vue-property-decorator'
import Discord from '@/services/Connections/Discord'
import { Admin } from '@/api'
import { Toast } from '@/mixins'

@Component
export default class UserInfoCard extends Mixins(Discord, Toast) {
  @Prop() user

  get userInfos() {
    return [
      { name: 'Account ID', value: this.user.id, icon: 'UserIcon' },
      { name: 'Username', value: this.user.username, icon: 'UserIcon' },
      { name: 'Authority', value: this.user.displayAuthority, icon: 'StarIcon' },
      { name: 'OC', value: this.user.money, icon: 'DollarSignIcon' },
      { name: 'Discord', value: this.getDiscordIdentity(this.user), icon: 'LinkIcon' },
      { name: '2FA', value: this.user.twoFactorActivated ? 'Enabled' : 'Disabled', icon: 'SmartphoneIcon' }
    ]
  }
}
